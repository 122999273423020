<ui-header [full]="true" (logoClick)="navigateToMain()">

</ui-header>

<ui-body [full]="true">

  <div class='container'>
    <h1 class="title">File upload</h1>
    <ng-content select="center-region">

    </ng-content>
  </div>
</ui-body>
