export class CopyUtilities {
	public static copyToClipboard(text: string, isMultiLineText?: boolean): void {
		const scrollYPos: number = window.scrollY;

		try {
			const tagName: string = isMultiLineText ? 'textarea' : 'input';
			const input: any = document.createElement(tagName);
			input.style.position = 'fixed';
			document.body.appendChild(input);
			input.value = text;
			input.focus();
			const rangeEnd: number = isMultiLineText ? text.length : 999;
			input.setSelectionRange(0, rangeEnd);
			document.execCommand('copy');
			document.body.removeChild(input);
		} finally {
			window.scrollTo(0, scrollYPos);
		}
	}
}
