import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OopsComponent } from './views/shared/oops/oops.component';
import { VideoUploadContainerComponent } from './views/video-upload-container/video-upload-container.component';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
	{
		path: '',
		component: VideoUploadContainerComponent,
		canActivate: [AuthGuard],
	},
	{ path: 'oops', component: OopsComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
})
export class AppRoutingModule { }
