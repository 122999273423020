import { AppStage } from "./app-stage.enum";
import { IConfig } from "./config.type";
import { config } from './config';


export class ConfigService {
	private static _config: IConfig;

	public static get config(): IConfig {		
		this._config = Object.freeze({
			...config
		});
		return this._config;
	}

	public static isProduction(): boolean {
		return this.config.STAGE === AppStage.production;
	}

	public static isSandbox(): boolean {
		return this.config.STAGE === AppStage.sandbox;
	}

	public static isDevelopment(): boolean {
		return this.config.STAGE === AppStage.development;
	}

	public static isCypress(): boolean {
		return this.config.STAGE === AppStage.cypress;
	}

	public static getStage(): AppStage {
		return this.config.STAGE;
	}
}
