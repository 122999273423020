import { Component, AfterViewInit, ViewChild } from '@angular/core';
import {
	UIListComponent,
	UINotificationService,
	INotificationConfig,
} from '@bannerflow/ui';
import { AppComponentStore } from 'src/app/app.component.store';
import { CopyUtilities } from 'src/app/shared/utilities/copy-utilities';
import { SizeUtility } from 'src/app/shared/utilities/size-utility';

@Component({
	selector: 'video-list',
	templateUrl: './video-list.component.html',
	styleUrls: ['./video-list.component.scss'],
})
export class VideoListComponent implements AfterViewInit {
	@ViewChild('list') public list: UIListComponent;

	constructor(
		private notificationService: UINotificationService,
		public appComponentStore: AppComponentStore
	) {}

	public ngAfterViewInit(): void {
		this.appComponentStore.list = this.list;
	}

	public copyUrlToClipboard(copyValue: string): void {
		const config: INotificationConfig = {
			type: 'success',
			autoCloseDelay: 5000,
			placement: 'top',
		};
		const msg = 'Url copied to clipboard.';

		CopyUtilities.copyToClipboard(copyValue);
		this.notificationService.open(msg, config);
	}

	public navigate(value: string): void {
		window.open(value, '_blank');
	}

	public roundToDecimals(decimals: number, someSize: number): string {
		return SizeUtility.roundToDecimals(decimals, someSize);
	}
}
