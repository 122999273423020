import { Component } from '@angular/core';
import { ConfigService } from 'src/app/core/config/config.service';

@Component({
	selector: 'oops',
	templateUrl: './oops.component.html',
	styleUrls: ['./oops.component.scss'],
})
export class OopsComponent {
	public gotoMain(): void {
		location.href = `${ConfigService?.config?.CLIENT_ROOT}`;
	}
}
