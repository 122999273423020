<div [ngClass]="{'is-empty': appComponentStore.loadIsListEmpty() | async }">

    <div id="ui-list-wrapper" >
        <ui-list #list [outerBorder]="true" [dataSource]="appComponentStore.loadDataSource() | async">
            <ui-list-column name="File Name" [sortable]="true" property="fileName" width="350px">
                <ng-template  let-data="data" let-value="value" ui-list-cell-template>
                    <span class="file-text" [uiTooltip]="data.fileName" uiTooltipMaxWidth="400px" uiTooltipPosition="top">
                            {{value}}
                    </span>
                </ng-template>
            </ui-list-column>
            <ui-list-column name="File Size" property="fileSizeMegabytes" width="120px">
                <ng-template let-data="data" let-value="value" ui-list-cell-template>
                    {{ roundToDecimals(2, value) }} MB
                </ng-template>
            </ui-list-column>
            <ui-list-column name="Size" [sortable]="true" property="sizeFormat" width="100px">
                <ng-template let-data="data" ui-list-cell-template>
                    <span class="text-wrapper" *ngIf="(data.status === 'Uploaded')">{{data.sizeFormat}}</span>
                    <ui-loader *ngIf="(data.status === 'Uploading')" class="loader" ></ui-loader>
                </ng-template>
            </ui-list-column>
            <ui-list-column name="Status" property="status" width="150px">
                <ng-template let-data="data" ui-list-cell-template>
                    <span *ngIf="(data.status === 'Uploading')"> Uploading...</span>
                    <span *ngIf="(data.status === 'Uploaded')" > Uploaded</span>
                    <span *ngIf="(data.status === 'Skipped')"> Skipped</span>
                </ng-template>
            </ui-list-column>
            <ui-list-column name="URL" property="url">
                <ng-template let-data="data" let-value="value" ui-list-cell-template>
                    <div class="text-wrapper" *ngIf="(data.status === 'Uploaded')">
                        <a
                            href="javascript:void(0)"
                            class="copy-on-hover"
                            (click)="copyUrlToClipboard(data.url)">
                            <ui-svg-icon icon="copy"></ui-svg-icon>
                        </a>
                        <span
                            class="file-text"
                            [uiTooltip]="data.url"
                            uiTooltipMaxWidth="400px"
                            uiTooltipPosition="top">

                            <a [href]="value" (click)='navigate(data.url)'>
                              {{ data?.url }}
                            </a>
                        </span>
                    </div>
                    <ui-loader *ngIf="(data.status === 'Uploading')" class="loader" ></ui-loader>
                </ng-template>
            </ui-list-column>
        </ui-list>
    </div>

    <div *ngIf="appComponentStore.loadIsListEmpty() | async" id="list-content">
        <p>Add files by clicking the 'Add Files' button or drag and drop files here</p>
    </div>


</div>
