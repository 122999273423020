import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
	public intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			tap(
				() => {
					// Success Response
				},
				() => {
					// Error Response
					// this.uiNotificationService.open('Something went wrong. Please try again.', { type: 'error', placement: 'top' , autoCloseDelay: 2000 });
				}
			)
		);
	}
}
