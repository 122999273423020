export interface IVideo {
	id: string;
	fileName: string;
	fileSizeMegabytes: number;
	sizeFormat?: string;
	status: UploadStatus;
	width?: string;
	height?: string;
	url?: string;
	file?: File;
	fileAsArrayBuffer?: string | ArrayBuffer;
}

export interface IVideoViewModel extends IVideo {
	disabled?: boolean;
}

export interface IVideoResponse {
	videoUrl: string;
}

export enum UploadStatus {
	Uploading = 'Uploading',
	Uploaded = 'Uploaded',
	Skipped = 'Skipped',
}

export enum FileSizeLimitMb {
	Display = 2,
	Social = 50,
}

export const allowedFileExtentions: string[] = [
	'mp4',
	'mov',
	'webm',
	'mp3',
	'json',
];
