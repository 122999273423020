import { Component, inject } from '@angular/core';
import { UIThemeService } from '@bannerflow/ui';
import { AppComponentStore } from './app.component.store';
import { SentinelService } from '@bannerflow/sentinel';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	providers: [AppComponentStore],
})
export class AppComponent {
	public title = 'video-upload';
	private sentinelService = inject(SentinelService);

	constructor(private uiThemeService: UIThemeService) { }
}
