import { Injectable, ErrorHandler } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
	providedIn: 'root',
})
export class AppErrorHandler implements ErrorHandler {
	private isEnvironmentLocal: boolean;

	constructor() {
		this.isEnvironmentLocal = !(
			ConfigService.isSandbox() ||
			ConfigService.isProduction()
		);
	}

	handleError(error: any): void {
		console.error('An error occurred:', error);
	}
}
