import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ResponseInterceptor } from './interceptors/response.interceptor';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

@NgModule({
    declarations: [],
    bootstrap: [], imports: [], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class CoreModule { }
