import { Injectable } from '@angular/core';
import {
	IUIListDataNode,
	UIListComponent,
	UIListDataSource,
} from '@bannerflow/ui';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { FileSizeLimitMb, IVideoViewModel } from './shared/models/video.model';

export enum VideoTypeEnum {
	display = 'display',
	social = 'social',
}

export interface IVideoUploadState {
	selectedTab: VideoTypeEnum;
	limitMb: FileSizeLimitMb;
	isListEmpty: boolean;
	displayDataSource: UIListDataSource<IVideoViewModel[]>;
	socialDataSource: UIListDataSource<IVideoViewModel[]>;
	hasUserAcceptedTerms: boolean;
}

@Injectable()
export class AppComponentStore extends ComponentStore<IVideoUploadState> {
	public list: UIListComponent;

	constructor() {
		super({
			selectedTab: VideoTypeEnum.display,
			limitMb: FileSizeLimitMb.Display,
			isListEmpty: true,
			displayDataSource: new UIListDataSource(),
			socialDataSource: new UIListDataSource(),
			hasUserAcceptedTerms: false,
		});
	}

	public updateListItem(newVideo: IVideoViewModel): void {
		this.loadSelectedTab().subscribe((selectedTab) => {
			switch (selectedTab) {
				case VideoTypeEnum.display:
					this.loadDisplayDataSource()
						.pipe(take(1))
						.subscribe((displayDataSource) => {
							const node: IUIListDataNode = displayDataSource.find(
								(nodeItem: IUIListDataNode) => nodeItem.id === newVideo.id
							);
							displayDataSource.update(node, newVideo);
							this.updateIsListEmpty(displayDataSource?.data?.length === 0);
						});
					break;
				case VideoTypeEnum.social:
					this.loadSocialDataSource()
						.pipe(take(1))
						.subscribe((socialDataSource) => {
							const node: IUIListDataNode = socialDataSource.find(
								(nodeItem: IUIListDataNode) => nodeItem.id === newVideo.id
							);
							socialDataSource.update(node, newVideo);
							this.updateIsListEmpty(socialDataSource?.data?.length === 0);
						});
					break;
			}
		});

		this.list.detectChanges();
	}

	readonly setSelectedTab = this.effect(
		(selectedTab$: Observable<VideoTypeEnum>) =>
			selectedTab$.pipe(
				tap((selectedTab: VideoTypeEnum) => {
					this.updateSelectedTab(selectedTab);
					switch (selectedTab) {
						case VideoTypeEnum.display:
							this.updateLimitMb(FileSizeLimitMb.Display);
							break;
						case VideoTypeEnum.social:
							this.updateLimitMb(FileSizeLimitMb.Social);
							break;
					}
				})
			)
	);

	readonly updateTermsAcceptance = this.updater(
		(state: IVideoUploadState, hasUserAcceptedTerms: boolean) => ({
			...state,
			hasUserAcceptedTerms,
		})
	);

	readonly updateDisplayDataSource = this.updater(
		(
			state: IVideoUploadState,
			displayDataSource: UIListDataSource<IVideoViewModel[]>
		) => ({
			...state,
			displayDataSource,
		})
	);

	readonly updateSelectedTab = this.updater(
		(state: IVideoUploadState, tab: VideoTypeEnum) => ({
			...state,
			selectedTab: tab,
		})
	);

	readonly updateLimitMb = this.updater(
		(state: IVideoUploadState, limitMb: FileSizeLimitMb) => ({
			...state,
			limitMb,
		})
	);

	readonly updateIsListEmpty = this.updater(
		(state: IVideoUploadState, isListEmpty: boolean) => ({
			...state,
			isListEmpty,
		})
	);

	public loadSelectedTab(): Observable<VideoTypeEnum> {
		return this.select((state) => state.selectedTab);
	}

	public loadLimitMb(): Observable<FileSizeLimitMb> {
		return this.select((state) => state.limitMb);
	}

	public loadHasUserAcceptedTerms(): Observable<boolean> {
		return this.select((state) => state.hasUserAcceptedTerms);
	}

	public loadIsListEmpty(): Observable<boolean> {
		return this.select((state) => state.isListEmpty);
	}

	public loadDisplayDataSource(): Observable<
		UIListDataSource<IVideoViewModel[]>
	> {
		return this.select((state) => state.displayDataSource);
	}

	public loadSocialDataSource(): Observable<
		UIListDataSource<IVideoViewModel[]>
	> {
		return this.select((state) => state.socialDataSource);
	}

	public loadDataSource(): Observable<UIListDataSource<IVideoViewModel[]>> {
		return this.loadSelectedTab().pipe(
			take(1),
			switchMap((selectedTab) => {
				switch (selectedTab) {
					case VideoTypeEnum.display:
						return this.loadDisplayDataSource();
					case VideoTypeEnum.social:
						return this.loadSocialDataSource();
				}
			})
		);
	}
}
