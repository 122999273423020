import { AppStage } from "./app-stage.enum";
import { IConfig } from "./config.type";

export const config: IConfig = {
    IDP_URL: "https://login.bannerflow.com",
    CLIENT_ROOT: "https://videoupload.bannerflow.com/",
    API_URL: "https://api.bannerflow.com/video-upload/",
    B2_URL: "https://app.bannerflow.com/",
    GTM_ID: "GTM-W5CTW5Z",
    STAGE: AppStage.production,
    RELEASE_NAME: "20240820.1_185527",
    CLIENT_ID: "lp4EwWl7MGk3BsDxWyGC7BvMkNOur1x1"
};
