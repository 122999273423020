import {
	Component,
	OnInit,
	OnDestroy,
	ViewChild,
	ElementRef,
} from '@angular/core';
import {
	UINotificationService,
	UIDialogService,
	INotificationConfig,
	IUIDialogConfig,
	UIDialogRef,
} from '@bannerflow/ui';
import { Observable, Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { VideoTypeEnum, AppComponentStore } from '../app.component.store';
import { VideoApiService } from '../core/services/video-api.service';
import {
	FileSizeLimitMb,
	IVideoViewModel,
	UploadStatus,
	IVideoResponse,
	allowedFileExtentions,
} from '../shared/models/video.model';
import { CopyUtilities } from '../shared/utilities/copy-utilities';
import { DownloadUtilities } from '../shared/utilities/download-utilities';
import { SizeUtility, SizeUnit } from '../shared/utilities/size-utility';
import { safelyUnSubScribe } from '../shared/utilities/sub-utilities';
import { UniqueIdUtility } from '../shared/utilities/unique-id-utility';
import {
	VideoUtilites,
	IVideoDimensions,
} from '../shared/utilities/video-utilities';
import { TermsConditionsDialogComponent } from './components/terms-conditions-dialog/terms-conditions-dialog.component';

@Component({
	selector: 'video-upload-content',
	templateUrl: './video-upload-content.component.html',
	styleUrls: ['./video-upload-content.component.scss'],
})
export class VideoUploadContentComponent implements OnInit, OnDestroy {
	@ViewChild('fileInput') public fileInput: ElementRef;

	private selectedTab: VideoTypeEnum;
	public hasUserAcceptedTerms: boolean;

	public limitMb$: Observable<FileSizeLimitMb>;
	public hasAcceptedSubscription$: Subscription;
	public selectedTabSubscription$: Subscription;
	public allowedFileExtentions = allowedFileExtentions;

	constructor(
		private videoApiService: VideoApiService,
		private notificationService: UINotificationService,
		public appComponentStore: AppComponentStore,
		private dialogService: UIDialogService
	) {}

	ngOnInit(): void {
		this.limitMb$ = this.appComponentStore.loadLimitMb();

		this.selectedTabSubscription$ = this.appComponentStore
			.loadSelectedTab()
			.pipe()
			.subscribe((videoType) => {
				this.selectedTab = videoType;
			});

		this.hasAcceptedSubscription$ = this.appComponentStore
			.loadHasUserAcceptedTerms()
			.pipe()
			.subscribe((hasAccepted) => {
				this.hasUserAcceptedTerms = hasAccepted;
			});
	}

	private isHeavyVideo(): boolean {
		return this.selectedTab === VideoTypeEnum.social;
	}

	public async addFiles(files: FileList, maxLimitMb: number): Promise<void> {
		// Handle heavy videos terms agreement
		if (this.isHeavyVideo() && !this.hasUserAcceptedTerms) {
			const isUserAcceptingTerms: boolean = await this.openWizard();

			this.appComponentStore.updateTermsAcceptance(isUserAcceptingTerms);

			if (!isUserAcceptingTerms) {
				return;
			}
		}

		const videos: IVideoViewModel[] = this.mapFilesToVideos(files, maxLimitMb);

		// reset file value from HTMLInputElement
		this.fileInput.nativeElement.value = null;

		this.insertVideosToDataSource(videos);

		this.readAndUploadVideos(videos);
	}

	public readAndUploadVideos(videos: IVideoViewModel[]): void {
		videos.forEach((video) => {
			if (video.status === UploadStatus.Uploading) {
				this.readUploadVideo(video);
			}
		});
	}

	public mapFilesToVideos(
		files: FileList,
		maxLimitMb: number
	): IVideoViewModel[] {
		return Array.from(files).map((file) => this.mapVideoFile(file, maxLimitMb));
	}

	public insertVideosToDataSource(videos: IVideoViewModel[]): void {
		this.appComponentStore
			.loadDataSource()
			.pipe(take(1))
			.subscribe((dataSource) => {
				dataSource.insert(videos);
				this.appComponentStore.updateIsListEmpty(false);
			});
	}

	public mapVideoFile(file: File, maxLimitMb: number): IVideoViewModel {
		const isValid: boolean = this.isVideoValid(file, maxLimitMb);

		return {
			id: UniqueIdUtility.createUUID(),
			fileName: file.name,
			fileSizeMegabytes: SizeUtility.convertBytesTo(file.size, SizeUnit.MB),
			status: isValid ? UploadStatus.Uploading : UploadStatus.Skipped,
			file,
			disabled: !isValid,
		};
	}

	public copyUploadedfilesToClipBoard(): void {
		let allUploadedVideosUrls: string;

		this.getUploadedUrls().subscribe((uploadedUrls) => {
			allUploadedVideosUrls = uploadedUrls.join('\n\n').trim();
		});

		CopyUtilities.copyToClipboard(allUploadedVideosUrls, true);

		const config: INotificationConfig = {
			type: 'success',
			autoCloseDelay: 5000,
			placement: 'top',
		};

		const msg = 'Urls copied to clipboard.';

		this.notificationService.open(msg, config);
	}

	public downloadUploadedfilesAsTxt(): void {
		let allUploadedVideosUrls: string;

		this.getUploadedUrls().subscribe((uploadedUrls) => {
			allUploadedVideosUrls = uploadedUrls.join('\n\n').trim();
		});

		DownloadUtilities.downloadToText(allUploadedVideosUrls, 'videos.txt');

		const config: INotificationConfig = {
			type: 'success',
			autoCloseDelay: 5000,
			placement: 'top',
		};

		const msg = 'Urls downloaded successfully to txt file.';

		this.notificationService.open(msg, config);
	}

	public getUploadedUrls(): Observable<string[]> {
		return this.appComponentStore.loadDataSource().pipe(
			take(1),
			map((dataSource) =>
				Array.from(dataSource.data)
					.filter((video) => video.status === UploadStatus.Uploaded)
					.map((video) => video.url)
			)
		);
	}

	private isVideoValid(file: File, maxLimitMb: number): boolean {
		// Convert size from bytes to MB
		const fileSize: number = SizeUtility.convertBytesTo(file.size, SizeUnit.MB);
		const fileExtention = this.getFileExtension(file.name);

		// Size should not be more than 2MB
		return (
			this.allowedFileExtentions.includes(fileExtention) &&
			fileSize <= maxLimitMb
		);
	}

	public getFileExtension(filename: string): null | string {
		if (filename.indexOf('.') === -1) {
			return null;
		}

		return filename.split('.').pop();
	}

	public readUploadVideo(video: IVideoViewModel): void {
		VideoUtilites.readVideoContent(video.file).subscribe(
			(content: Uint8Array): void => {
				VideoUtilites.readVideoDimensions(video.file).subscribe(
					(videoDimensions: IVideoDimensions): void => {
						this.videoApiService
							.uploadVideo(content, video.fileName, video.file.type)
							.subscribe(
								// Success
								(videoResponse: IVideoResponse) => {
									video.sizeFormat = `${videoDimensions.videoWidth} x ${videoDimensions.videoHeight}`;
									video.url = videoResponse.videoUrl;
									this.updateStatus(video, UploadStatus.Uploaded);
									this.appComponentStore.updateListItem(video);
								},
								// Error
								() => {
									this.updateStatus(video, UploadStatus.Skipped);
									this.appComponentStore.updateListItem(video);
								}
							);
					}
				);
			}
		);
	}

	private updateStatus(video: IVideoViewModel, newStatus: UploadStatus): void {
		video.status = newStatus;
		video.disabled = newStatus === UploadStatus.Skipped ? true : false;
	}

	public async openWizard(): Promise<boolean> {
		const dialogConfig: IUIDialogConfig = {
			headerText: 'heavy video upload terms and conditions',
			width: '850px',
			height: '500px',
			backdropClickClose: false,
			padding: 0,
		};

		const dialogRef: UIDialogRef = this.dialogService.openComponent(
			TermsConditionsDialogComponent,
			dialogConfig
		);

		await dialogRef.afterViewInit;
		const dialogResult = await dialogRef.subComponentRef.instance.initiate();
		dialogRef.close();

		return dialogResult;
	}

	public ngOnDestroy(): void {
		safelyUnSubScribe([
			this.hasAcceptedSubscription$,
			this.selectedTabSubscription$,
		]);
	}
}
