import { Observable, Subscriber } from 'rxjs';

export class VideoUtilites {
	/*
        takes event of file and return videofileobject-details
    */
	public static readVideoContent(videoFile: File): Observable<Uint8Array> {
		const fileReader: FileReader = new FileReader();
		// init read
		fileReader.readAsArrayBuffer(videoFile);

		return Observable.create((observer: Subscriber<Uint8Array>): void => {
			// if success
			fileReader.onload = (ev: any): void => {
				const bytes: Uint8Array = new Uint8Array(ev.target.result);

				observer.next(bytes);
				observer.complete();
			};
		});
	}

	/*
        takes event of file and return video width/height
    */
	public static readVideoDimensions(
		videoFile: File
	): Observable<IVideoDimensions> {
		return Observable.create((observer: Subscriber<IVideoDimensions>): void => {
			const url: string = URL.createObjectURL(videoFile);

			const video: HTMLVideoElement = document.createElement('video');

			const resolveObserver: (dimensions: IVideoDimensions) => void = (
				dimensions
			) => {
				video.remove();
				observer.next(dimensions);
				observer.complete();
			};

			video.onloadedmetadata = (): void => {
				// Revoke when you don't need the url any more to release any reference
				URL.revokeObjectURL(url);

				const videoDimensions: IVideoDimensions = {
					videoWidth: video.videoWidth.toString(),
					videoHeight: video.videoHeight.toString(),
				};

				resolveObserver(videoDimensions);
			};

			video.addEventListener('error', () => {
				URL.revokeObjectURL(url);

				const videoDimensions: IVideoDimensions = {
					videoWidth: 'unknown',
					videoHeight: 'unknown',
				};
				resolveObserver(videoDimensions);
			});

			video.src = url;
			video.load();
		});
	}
}

export interface IVideoDimensions {
	videoWidth: string;
	videoHeight: string;
}
